<template>
  <div >
    <div class="mainCont">
      <div class="containerHeader">
      <p class="title">{{message=='1'?'关于我们':'About us'}}</p>
    </div>
    <div  class="containerBody">

      <div class="contentCont">
        <p v-if="message=='1'">
          闪抓是一款非常方便的文件传输及分享应用程序之一，以其卓越的便捷性，为用户提供了无需消耗流量即可在不同设备间快速分享各类文件的功能。无论是音乐、电影还是图片，用户都可以通过闪抓实现跨平台的无缝传输。这一服务不依赖数据线或移动数据网络，确保了传输过程的独立性和安全性。
          </p>

         <p v-if="message=='1'"> 依托于创新技术和强大的服务器网络技术，闪抓不仅实现了手机间的文件互传，更扩展至手机与电脑等多平台的无缝连接。我们的多平台互传功能，结合了多项先进技术特性，为用户提供了更加丰富和高效的使用体验。</p>
          
          <p v-else>
            Flash is a very convenient file transfer and sharing application. One of its outstanding conveniences is that it provides users with the function of quickly sharing various files between different devices by consuming data. Whether it’s music, movies or pictures, users can achieve seamless transfer across platforms through Flash Grab. The service does not rely on data lines or mobile data networks, ensuring the independence and security of the transmission process.
Relying on innovative technology and powerful server network technology, Shanzhu not only realizes file transfer between mobile phones, but also extends to seamless connections between mobile phones and computers and other multiple platforms. Our multi-platform mutual transmission function, combined with the demonstration of advanced technical features, provides users with a richer and more efficient experience.
          </p>
        </div>
      <div class="titleCont">{{message=='1'?'联系我们':'Contact us'}}</div>
      <div class="imgCont" @click="copyContent">
        <img v-if="message=='1'" src="../../assets/Images/contTactus1.png" alt="">
        <img v-else src="../../assets/Images/contTactus.png" alt="">
      </div>
    </div>
    </div>
    <Footer></Footer>
    
  </div>
 </template>
 <script>
  import { EventBus } from '../../store/eventBus.js';
  import Footer from '../../components/footer/index.vue';
  import { Toast  } from 'vant';
 export default {
  components: {
    Footer
  },
   data() {
      return{
        message:'1'
     };
   },
   watch: {
     
   },
   onLoad() {
   },
   mounted() {
    EventBus.$on('custom-event', this.receiveMessage);
    this.receiveMessage(window.localStorage.getItem('message')||'1')
   },
   methods: {
    receiveMessage(message) {
      this.message = message;
    },
    copyContent() {
      // 创建一个临时的 textarea 元素
      const tempTextarea = document.createElement('textarea');
      // 设置 textarea 的内容
      tempTextarea.value = '1626244507@qq.com';
      // 将 textarea 放到 body 中，但不显示
      document.body.appendChild(tempTextarea);
      // 选中 textarea 中的内容
      tempTextarea.select();
      // 执行浏览器的复制命令
      document.execCommand('copy');
      // 从 body 中移除临时的 textarea
      document.body.removeChild(tempTextarea);
      Toast.success(this.message=='1'?'内容已复制到剪贴板！':'The content has been copied to the clipboard!');
      // // 提示用户复制成功
      // alert('内容已复制到剪贴板！');
    }
   }
 };
 </script>
 
 <style lang="less" scoped>
 .containerHeader{
  width: calc(100vw - 240px);
  padding: 0 120px;
  height: 200px;
  background: #D2D7F3;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  font-size: 36px;
  p{
    margin: 0 0 20px 0;
  }
 }
 .mainCont{height: calc(100vh - 170px);
 }
 .containerBody{
  width: calc(100vw - 240px);
  padding: 0 120px;
  .contentCont{
    padding: 20px 0;
    p{
      margin: 0;
      line-height: 1.5;
      font-size: 14px;
    }
  }
  .titleCont{
    font-size: 26px;
    line-height: 42px;
  }
  .imgCont{
    width: 240px;
    img{
      width: 100%;
      display: block;
      border-radius: 12px;
      transition: transform 0.5s ease, box-shadow 0.5s ease; /* 1秒的缓慢过渡效果 */
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.01); /* 初始阴影 */
      transform: scale(1); /* 初始缩放比例 */
      cursor: pointer;
      &:hover{
        transform: scale(1.05); /* 鼠标悬停时放大到110% */
        box-shadow: 0 4px 16px rgba(11, 190, 141, 0.8); /* 鼠标悬停时增加阴影 */
      }
    }
  }
 }
 @media (max-width: 480px) {
  .containerHeader{
    width: calc(100vw - 40px);
    padding: 0 20px;
    height: 140px;
  }
  .mainCont{
    height: auto;
  }
  .containerBody{
    width: calc(100vw - 40px);
    height: auto;
    padding: 0 20px;
    .contentCont{
      padding: 20px 0;
      p{
        margin: 0;
        line-height: 1.5;
        font-size: 16px;
      }
    }
  }
 }
 </style>