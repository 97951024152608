<template>
  <div class="layout">
    <div class="header">
      <div style="display: flex;align-items: center;justify-content: space-between;">
        <img class="logo" :src="languageType=='1'?chineseImg:EnglishImg" alt="" @click="handleClick" style="margin-right: 120px;"/>
        <el-menu v-if="windowWidth>=480" :default-active="activeIndex" class="el-menu-demo" text-color="#272442" active-border-color="#A45FFF" active-text-color="#272442" border-color="#fff" mode="horizontal" @select="handleSelect">
          <el-menu-item index="/">首页</el-menu-item>
          <el-submenu index="2">
            <template slot="title">其他产品</template>
            <el-menu-item index="2-1" style="display: flex;align-items: center;">
              <img width="46px" height="46px" src="../../assets/Images/productOne.png" alt="">
              <a href="http://www.shanzhua.com/2008/index" style="color: #272442;font-size: 16px;font-weight: bold;" target="_blank">手机云盘</a>
            </el-menu-item>
          </el-submenu>
        </el-menu>
        
      </div>
      <div style="display: flex;align-items: center;">
        <div class="language-select" style="display: flex;align-items: center;">
          <el-dropdown trigger="click" @command="changeLanguage">
            <span class="el-dropdown-link" style="display: flex;align-items: center;cursor: pointer;">
              <img src="../../assets/Images/language.png" width="16px" height="16px" alt="" >
              <!-- <span style="margin-right: 10px;color: #111;font-weight: bold;font-size: 16px;">{{languageType=='1'?'中文':'English'}}</span> -->
              <!-- <i class="el-icon-arrow-down el-icon--right"></i> -->
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="1">中文</el-dropdown-item>
              <el-dropdown-item command="2">English</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="language-select" v-if="windowWidth < 480 && !listCollapse " style="display: flex;align-items: center;" @click="listCollapse=!listCollapse">
          <img   src="../../assets/Images/level.png" width="18px" height="20px" alt="" >
        </div>
        <div class="language-select" v-if="windowWidth < 480 && listCollapse " style="display: flex;align-items: center;" @click="listCollapse=!listCollapse">
          <img  src="../../assets/Images/close.png" width="18px" height="20px" alt="" >
        </div>
      </div>

    </div>
   <div v-if="windowWidth < 480&&listCollapse" style="position: absolute;top: 50px;left: 0;z-index: 999;width: calc(100% - 40px);padding: 0px 20px;background-color: #fff;">
    <el-collapse v-model="activeName" accordion @change="handleCollapse">
      <el-collapse-item title="" name="1">
        <template slot="title" slot-scope="slot">
          <div style="color: #272442;font-size: 16px;font-weight: bold;">
            其他产品
          </div>
        </template>
        <div style="display: flex;align-items: center;margin-right: 20px;">
          <img width="32px" height="32px" src="../../assets/Images/productOne.png" alt="">
          <a href="http://www.shanzhua.com/2008/index" style="color: #272442;font-size: 15px;font-weight: bold;" target="_blank">手机云盘</a>
        </div>
      </el-collapse-item>
      <el-collapse-item title="" name="2" class="aboutUs">
        <template slot="title" slot-scope="slot">
          <a style="color: #272442;font-size: 16px;font-weight: bold;" href="http://www.shanzhua.com/2000/tag">
            关于我们
          </a>
        </template>
      </el-collapse-item>
    </el-collapse>
   </div>

    <main class="main-content" >
      <router-view></router-view>
    </main>
  </div>
</template>

<script>
import { EventBus } from '../../store/eventBus';
export default {
  name: "Layout",
  components: {
  },
  data(){
    return{
      activeName:'1',
      activeIndex:'/',
      languageType:'1',
      chineseImg:require('../../assets/Images/logo1.png'),
      EnglishImg:require('../../assets/Images/logo.png'),
      windowWidth:0,
      listCollapse:false
    }
  },
  mounted() {
    this.windowWidth = window.innerWidth;
    this.changeLanguage(window.localStorage.getItem('message')||'1')
  },
  onLoad() {},
  methods: {
    handleCollapse(e){
      console.log(e);
      if(e=='2'){
        this.listCollapse = !this.listCollapse;
        this.$router.push('/tag');
      }
    },
    handleSelect(){

    },
    handleClick(){
      this.$router.push('/');
    },
    changeLanguage(item){
      console.log(item);
      this.languageType = item;
      EventBus.$emit('custom-event', item);
    }
  },
};
</script>

<style lang="less" scoped>
.header{
  height: 60px;
  width: calc(100vw - 240px);
  padding: 0 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  .logo{
    height: 46px;
    cursor: pointer;
  }
  .el-menu-demo{
    font-weight: bold;
    font-size: 18px;
    border: none!important;;
    .el-menu-item{
      font-size: 18px!important;
      
    }
    .el-submenu{
      font-size: 18px!important;
      /deep/.el-submenu__title{
        font-size: 18px!important;
      }
    }
  }
  .language-select{
    padding: 5px 10px;
    background: #fff;
    border-radius: 6px;
    &:hover{
      background: #e6eaf9;
    }
  }
}
// 手机和小型设备的样式
@media (max-width: 480px) {
  /deep/.el-collapse-item__content{
      padding-bottom: 10px;
    }
    /deep/.aboutUs /deep/.el-collapse-item__arrow:last-child{
      display: none;
    }
  .header{
    height: 50px;
    width: calc(100vw - 40px);
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #EBEEF5;
    
    .logo{
      height: 36px;
    }
  }
}
.main-content {
  height: calc(100vh - 60px);
  overflow: auto;
}
/* 设置滚动条的宽度为16px */
::-webkit-scrollbar {
    width: 0; /* 针对Webkit浏览器 */
}

</style>
