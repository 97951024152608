import request from "./request";


export const getList = (data) => {
    return request({
        url: `/v1/start-info/list-json-file-content`, //对应mock中的接口
        method: "POST", //对应mock中的请求的type
        data,
    });
};


export const getAllMessageList = (data) => {
    return request({
        url: `/message/GetAllMessageList`, //对应mock中的接口
        method: "POST", //对应mock中的请求的type
        data,
    });
};