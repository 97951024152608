<template>
  <div class="tableCont">
    <van-row class="tableThCont">
      <van-col
        class="tableThItem"
        :span="24 / tableTh.length"
        v-for="(item, index) in tableTh"
        :key="index"
        >{{ item }}</van-col
      >
    </van-row>
    <van-row class="tableThCont" v-for="(item, idx) in dataCont" :key="idx">
      <van-col
        class="tableColItem"
        :span="24 / tableTh.length"
        v-for="(i, idc) in item"
        :key="idc"
        >{{ i }}</van-col
      >
    </van-row>
  </div>
</template>
<script>
export default {
  name: "Header",

  data() {
    return {
      tableTh: ["姓名", "性别", "年龄", "电话", "邮箱", "地址"],
      dataCont: [
        {
          name: "张三",
          gender: "男",
          age: "25",
          tel: "13812345678",
          email: "<EMAIL>",
          address: "北京市海淀区",
        },
        {
          name: "张三",
          gender: "男",
          age: "25",
          tel: "13812345678",
          email: "<EMAIL>",
          address: "北京市海淀区",
        },
        {
          name: "张三",
          gender: "男",
          age: "25",
          tel: "13812345678",
          email: "<EMAIL>",
          address: "北京市海淀区",
        },
      ],
    };
  },
};
</script>
<style lang="less" scoped>
.tableCont {
  border-bottom: #666666 solid 1px;
  border-right: #666666 solid 1px;
  .tableThCont {
    .tableThItem,
    .tableColItem {
      align-items: center;
      border-top: #666666 solid 1px;
      border-left: #666666 solid 1px;
    }
  }
}
</style>
