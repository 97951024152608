<template>
 
 <div class="chatRecord">
  <p class="title">会话记录</p>
  <div class="conditionCont" >
    <div>
      <el-date-picker
      class="pickCont" 
        v-model="pickTime"
        type="datetimerange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期">
      </el-date-picker>
      <el-select class="valueCont" v-model="sender" placeholder="请选择" >
        <el-option
          v-for="item in sendList"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <el-select class="valueCont" v-model="customerService" placeholder="请选择" >
        <el-option
          v-for="item in customerServiceList"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <el-select class="valueCont" v-model="source" placeholder="请选择" >
        <el-option
          v-for="item in sourceList"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
    </div>
    <el-button type="primary" >导出当前数据</el-button>
  </div>
  <div class="tableCont">
    <el-table
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%">
      <el-table-column
        type="selection"
        width="55">
      </el-table-column>
      <el-table-column
        label="日期"
        width="120">
        <template slot-scope="scope">{{ scope.row.date }}</template>
      </el-table-column>
      <el-table-column
        prop="name"
        label="姓名"
        width="120">
      </el-table-column>
      <el-table-column
        prop="address"
        label="地址"
        show-overflow-tooltip>
      </el-table-column>
    </el-table>

    <div class="paginationCont">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-size="12"
        layout="total, prev, pager, next"
        :total="12">
      </el-pagination>
    </div>
  </div>
 </div>
</template>


<script>
import io from "socket.io-client"; //引入socket.io-client
import tableCont from "@/components/Table/index.vue";
export default {
  components: {
    tableCont
  },
  metaInfo: {
    title: "会话记录",
  },
  data() {
      return {
        pickTime:'',//选择时间段

        sender:'',//发起方
        sendList:[
          {
            label:'值1',
            value:1
          },
          {
            label:'值2',
            value:2
          }
        ],//发起方的数组

        customerService:'',//客服
        customerServiceList:[
          {
            label:'值1',
            value:1
          },
          {
            label:'值2',
            value:2
          }
        ],//客服的数组
        
        source:'',//来源
        sourceList:[
          {
            label:'值1',
            value:1
          },
          {
            label:'值2',
            value:2
          }
        ],//来源的数组

        currentPage:1,//初始化页码
        tableData: [{
          date: '2016-05-03',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄'
        }, {
          date: '2016-05-02',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄'
        }, {
          date: '2016-05-04',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄'
        }, {
          date: '2016-05-01',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄'
        }, {
          date: '2016-05-08',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄'
        }, {
          date: '2016-05-06',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄'
        }, {
          date: '2016-05-07',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄'
        }],
        multipleSelection: []
      }
    },
  watch: {
    
  },
  onLoad() {
  },
  mounted() {
    console.log(this.$route.path);
  },
  methods: {
    handleSizeChange(){

    },
    handleCurrentChange(){

    }
    
  },
};
</script>

<style lang="less" scoped>
.chatRecord{
  .title{
    color: #111;
    font-weight: 600;
    font-size: 0.2rem;
    padding: 10px 20px;
  }
  .conditionCont{
    padding: 0 20px;
    margin: 10px 0 20px;
    display:flex;
    justify-content: space-between;
    align-items: center;
    div{
      .pickCont{
        width:320px;
        margin-right: 30px;
      }
      .valueCont{
        width:100px;
        margin-right: 30px;
      }
    }
    
  }
  .tableCont{
    padding: 0 20px;
    .paginationCont{
      display: flex;
      justify-content: flex-end;
      margin-top: 20px; 
    }
  }
}
</style>