import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "../components/Layout/Layout.vue";

import index from "../views/Chat.vue";
import Tag from "../views/tag";
import chatRecord from "../views/chatRecord";
import Self from "../views/self";
import commonUse from "../views/commonUse";
Vue.use(VueRouter);

const routes = [{
    path: "/",
    component: Layout,
    redirect: "/index",
    children: [{
            path: "index",
            component: index,
            query: {
                sendId: 2,
            },
        },
        {
            path: "record",
            component: chatRecord,
        },
        {
            path: "tag",
            component: Tag,
        },
        {
            path: "common",
            component: commonUse,
        },
        {
            path: "self",
            component: Self,
        },
    ],
}, ];

const router = new VueRouter({
    mode: "history",
    base: '/2000/',
    routes,
});

export default router;