
<template>
 
  <div class="pageContent">
    <div class="containerFooter" v-if="windowWidth>=480">
      <div class="inner">
       <p style="margin-right: 30px; cursor: pointer;" @click="otherPage('https://beian.miit.gov.cn/')"> Copyright © 2021  湘ICP备19000880号-15A</p>
        <img src="../../assets/Images/gongan.png" width="20px" alt="" style="margin-right: 10px;cursor: pointer;"> 
        <p @click="otherPage('http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=43010402001595')" style="cursor: pointer;">湘公网安备 43019002001608号</p>
      </div>
      <div class="about">
        <div @click="goPage('/tag')">{{aboutText1}}</div>
        <div @click="goPage('/self')">{{aboutText2}}</div>
        <div @click="goPage('/common')">{{aboutText3}}</div>
      </div>
   </div>
   <div class="containerFooter" v-else>
      <div class="about">
        <div @click="goPage('/tag')">{{aboutText1}}</div>
        <div @click="goPage('/self')">{{aboutText2}}</div>
        <div @click="goPage('/common')">{{aboutText3}}</div>
      </div>
      <div class="inner">
       <p style="display: flex;align-items: center;justify-content: center; cursor: pointer;" @click="otherPage('https://beian.miit.gov.cn/')"> Copyright © 2021  湘ICP备19000880号-15A</p>
        <div style="display: flex;align-items: center;justify-content: center;cursor: pointer;">
          <img src="../../assets/Images/gongan.png" width="20px" alt="" style="margin-right: 10px;"> 
          <p @click="otherPage('http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=43010402001595')" style="cursor: pointer;">湘公网安备 43010402001595号</p>
        </div>
      </div>
      
   </div>
  </div>
  
 </template>
 
 
 <script>
 import { EventBus } from '../../store/eventBus.js';
 export default {
  
   data() {
     return {
      message:'1',
      windowWidth:0,
      aboutText1:'',
      aboutText2:'',
      aboutText3:'',
     };
   },
   watch: {
     
   },
   created() {
      window.addEventListener('resize', this.handleResize);
      this.handleResize()
    },
    mounted() {
      EventBus.$on('custom-event', this.receiveMessage);
      this.receiveMessage(window.localStorage.getItem('message')||'1')
    
    },
    beforeDestroy() {
      EventBus.$off('custom-event', this.receiveMessage);
      window.removeEventListener('resize', this.handleResize);
    },
   methods: {
    otherPage(url){
      window.open(url, '_blank');
    },
    goPage(url) {
      this.$router.push(url);
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    receiveMessage(message) {
      this.message = message;
      window.localStorage.setItem('message', message)

      if(this.message=='1'){
        this.aboutText1 = '关于我们'
        this.aboutText2 = '隐私政策'
        this.aboutText3 = '用户协议'
      }else{
        this.aboutText1 = 'about us'
        this.aboutText2 = 'privacy policy'
        this.aboutText3 = 'user agreement'
      }
    }
     
   },
 };
 </script>
 <style lang="less" scoped>
.containerFooter{
  margin-top: 40px;
  width: calc(100vw - 240px);
  height: 60px;background: #272442;
  color: #fff;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 120px;
  .inner{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .about{
    width: 300px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
   div{
    &:hover{
      color: #6C65C6;
    }
   }
  }
}
// 手机和小型设备的样式
@media (max-width: 480px) {
  .containerFooter{
    width: calc(100vw - 40px);
    height: auto;
    padding: 0 20px;
    display: block;
    padding-bottom: 20px;
    p{
      margin: 0;
    }
    .about{
      width: 100%;
      padding:20px 0 40px;
    }
    .inner{
      display: block;
      line-height: 2;
    }
  }
}
 </style>