import axios from 'axios';

// 创建axios实例
const service = axios.create({
    // 基础URL
    baseURL: 'https://s5.fuguizhukj.cn/api',
    // 超时时间
    timeout: 10000, // 10秒
});

// 请求拦截器
service.interceptors.request.use(
    config => {
        // 在发送请求之前做些什么
        // 例如：设置请求头
        return config;
    },
    error => {
        // 处理请求错误
        return Promise.reject(error);
    }
);

// 响应拦截器
service.interceptors.response.use(
    response => {
        // 对响应数据做点什么
        return response.data;
    },
    error => {
        // 处理响应错误
        console.error('服务端返回错误:', error);
        return Promise.reject(error);
    }
);

export default service;