import Vue from 'vue'
import Vuex from 'vuex'

//仿数据库数据
const userList = require("../assets/json/userList.json");
const fastReply = require("../assets/json/fastReply.json");
const productList = require("../assets/json/product.json");
const messageList = require("../assets/json/messageList.json")
const robotReply = require("../assets/json/robotReply.json")
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userList: userList,
    conversitionList: [],
    fastReply: fastReply,
    productList: productList,
    messageList: messageList,
    robotReply: robotReply,
    service: localStorage.getItem('service'),
    socket: {}
  },
  mutations: {
    // 通用方法：更新数据方法
    setPropName (state, res) {
      // uni.showToast({
      // 	title: JSON.stringify(state)
      // })
      state[res.propName] = res.value;
    },
    setService (state, service) {
      state.service = service;
      localStorage.setItem('service', service)
    },
    setOnlineState (state, onlineState) {
      let stateService = JSON.parse(state.service);
      stateService.onlineState = onlineState;
      state.service = JSON.stringify(stateService);
      localStorage.setItem('service', JSON.stringify(stateService))
    }
  },
  actions: {},
  modules: {},
  service: {}
})