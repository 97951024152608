<template>
  <div >
   <div class="containerHeader">
    <div class="inner">
      <p class="title">{{ IndexCont.Title }}</p>
      <!-- <video src="https://www.w3schools.com/html/mov_bbb.mp4"></video> -->
      <video
      v-if="message=='1'"
        width="690"
        height="375"
        autoplay
      >
        <source src="https://yyires.nianyuxinxi.cn/scriptconfig/0/20240826/cbe1aafe.mp4" type="video/mp4">
      </video>
      <video
        v-else
        width="690"
        height="375"
        autoplay
      >
        <source src="https://yyires.nianyuxinxi.cn/scriptconfig/0/20240826/21984c6e.mp4" type="video/mp4">
      </video>
      
    </div>
    <!-- <canvas id="qrCanvas" style="width: 200px;height: 200px;"></canvas> -->
    <div class="inner row" v-if="windowWidth>=480">
      <div class="btnGruopCont" v-for="(item,index) in itemArray" :key="index" >
        <div class="btnGruop"  @click="downloadFile(item.type,item.downloadUrl)">
        <div class="imgCont"  v-if="item.type==1||item.type==3">
          <img :src="item.img" alt="">
        </div>
        <el-popover
          v-else
          placement="top"
          width="200"
          trigger="hover">
            <div>
              <!-- <div><img :src="item.url" width="100%" alt=""></div> -->
                <div>
                  <canvas :id="item.system" style="width: 200px;height: 200px;"></canvas>
                </div>
              <p style="font-size: 16px;font-weight: bold;text-align: center;color: #111;">{{message=='1'?item.descChinese: item.desc}}</p>
            </div>
            <img slot="reference" :src="item.img" alt="">
        </el-popover>
      </div>
        <p class="upDateTime">
         {{message=='1'?' 更新时间：'+item.updateTime: 'Translation update time:'+item.updateTime}}
        </p>
        
      </div>
      
    </div>
    <div class="myInner" v-else>
      <div class="btn" :style="{background:downLoadIndex==index?'#b6beeb':'#fff'}" v-for="(item,index) in iphoneArray" :key="index"  @click="downloadFile(item.type,item.downloadUrl,index)">
        <div>
        <img :src="item.img" alt="">
        <span>{{message=='1'?item.text:item.textEnglish}}</span>
        </div>
        <p class="upDateTime">
         {{message=='1'?' 更新时间：'+item.updateTime: 'Translation update time:'+item.updateTime}}
        </p>
      </div>
      <div class="btnClick">
        <el-button type="text" @click="moreDownload">{{ message=='1'?'桌面端下载':'Client download method' }}</el-button>
        <i class="el-icon-arrow-right"></i>
      </div>
    </div>
   </div>
   <div class="containerBody">
      <p class="contentTitle">{{conteTitle}}</p>
      <div class="row">
        <div class="skyCont" v-for="(item,index) in infoImg12" :key="index">
          <div class="imgCont">
            <img :src="item.img" alt=""/>
          </div>
          <p class="title">{{item.title}}</p>
          <p class="desc">{{item.desc}}</p>
        </div>
      </div>
      <div  class="row">
        <div class="skyCont" v-for="(item,index) in infoImg22" :key="index">
          <div class="imgCont">
            <img :src="item.img" alt="">
          </div>
          <p class="title">{{item.title}}</p>
          <p class="desc">{{item.desc}}</p>
        </div>
      </div>
   </div>
   <Footer></Footer>
   <van-popup v-model="show" position="bottom" :style="{ borderRadius:'16px 16px 0 0',background:'#F1F4FC',paddingBottom:'20px'}">
    <div class="headerContent">
      <span class="cancelBtn" @click="show=false">{{message=='1'?'取消':'Cancel'}}</span>
      <span class="sureBtn" @click="handleSureBtn(null)">{{message=='1'?'确定':'Confirm'}}</span>
    </div>
    <div class="bodyContent">
      <div class="loadRow" :style="{background:downLoadIndex==3?'#b6beeb':'#fff'}" @click="handleSureBtn(3)">
       <div class="inner">
          <img src="../assets/Images/iPhoneMac.png" alt="">
          <span>MacOS</span>
       </div>
       <img class="loadCont" src="../assets/Images/load.png" alt="">
      </div>
      <div class="loadRow" :style="{background:downLoadIndex==4?'#b6beeb':'#fff'}" @click="handleSureBtn(4)">
        <div class="inner">
          <img src="../assets/Images/iPhoneWindows.png" alt="">
          <span>Windows</span>
        </div>
        <img class="loadCont" src="../assets/Images/load.png" alt="">
      </div>
    </div>
   </van-popup>
  </div>
</template>

<script>
import { EventBus } from '../store/eventBus.js';
import Footer from '../components/footer/index.vue';
import Vue from 'vue';
import { Toast,Popup  } from 'vant';
import { getList } from '../api/message.js';
import QRCode from 'qrcode';
export default {
  components: {
    Footer,
    Popup
  },
  data(){
    return{
      show:false,
      downLoadIndex:null,
      IndexCont:{
        Title:'Download SwiftShare Now and Experience Secure, Speedy, Smart File Sharing'
      },
      itemArray: [
        {
          img: require('../assets/Images/row1.png'),
          url: 'http://d.nianyuxinxi.cn/ios/SwiftShare.dmg',
          type: 1,
          desc:'Scan QR code to download Apk',
          system: "MacOs"
        },
        {
          img: require('../assets/Images/row2.png'),
          url: 'http://d.nianyuxinxi.cn/update/shanzhua.exe',
          type: 1,
          desc:'Scan QR code to download Android App',
          system: "Windows"
        },
        {
          img: require('../assets/Images/row3.png'),
          url: require('../assets/Images/anzhuo.png'),
          type: 2,
          desc:'Scan QR code to download Apk',
          descChinese:'扫描二维码下载APK',
          system: "Android"
        },
        {
          img: require('../assets/Images/row4.png'),
          url: require('../assets/Images/guge.png'),
          type: 2,
          desc:'Scan QR code to download Android App',
          descChinese:'扫描二维码下载安卓APP',
          system: "GooglePlay"
        },
        {
          img: require('../assets/Images/row5.png'),
          url: require('../assets/Images/pingguo.png'),
          type: 2,
          desc:'Scan QR code to download iPhone App',
          descChinese:'扫描二维码下载苹果APP',
          system: "AppStore"
        },
      ],
      infoImg1:[
        {
          img:require('../assets/Images/info1.png'),
          title:'Safety',
          desc:'End-to-end encryption ensures that only you and the recipient can access your files'
        },
        {img:require('../assets/Images/info2.png'),
          title:'Cross-platform',
          desc:'Available for Windows, Macos, Android and ioS'
        },
        {img:require('../assets/Images/info3.png'),
          title:'Free',
          desc:'Free to use. No ads, no tracking, no hidden charges'
        }
      ],
      infoImg2:[
        {img:require('../assets/Images/info4.png'),
          title:'Easy to use',
          desc:'Simple user interface with no registration required. Other devices are automatically discovered'
        },
        {img:require('../assets/Images/info5.png'),
          title:'Zero flow',
          desc:'No traffic required, no consumption required to share with friends'
        },
        {img:require('../assets/Images/info6.png'),
          title:'Fast transfer',
          desc:'The average transmission speed can reach 10MB/S, 128 times faster than Bluetooth'
        }
      ],
      infoImg3:[
      {
          img:require('../assets/Images/info1.png'),
          title:'安全',
          desc:'端到端加密确保只有您和接收者可以访问您的文件'
        },
        {img:require('../assets/Images/info2.png'),
          title:'跨平台',
          desc:'可用于Windows、macOs、Android和i0S'
        },
        {img:require('../assets/Images/info3.png'),
          title:'免费',
          desc:'免费使用、无广告、无跟踪、无隐藏付费'
        }
      ],
      infoImg4:[
        {img:require('../assets/Images/info4.png'),
          title:'易于使用',
          desc:'无需注册的简单用户界面，其他设备会自动被发现'
        },
        {img:require('../assets/Images/info5.png'),
          title:'零流量',
          desc:'无需流量,与好友互传无需任何消耗'
        },
        {img:require('../assets/Images/info6.png'),
          title:'快速传',
          desc:'传输速度平均可达10MB/S，速度可达蓝牙128倍'
        }
      ],
      iphoneArray:[
        {
          img:require('../assets/Images/iphoneAnzhuo.png'),
          text:'APK 下载',
          url:'http://d.nianyuxinxi.cn/BD/sz.apk',
          type: 1,
          textEnglish:'APK download',
           system: "Android"
        },
        {
          img:require('../assets/Images/iphonePingguo.png'),
          text:'苹果商店',
          url:'https://apps.apple.com/cn/app/id6621238770',
          type: 1,
          textEnglish:'App Store',
          system: "AppStore"
        },
        {
          img:require('../assets/Images/iphoneGuge.png'),
          text:'谷歌商店',
          url:'http://d.fuguizhukj.cn/update/sz100os0816.apk',
          type: 1,
          textEnglish:'Google Play',
          system: "GooglePlay"
        },

      ],
      infoImg12:[],
      infoImg22:[],
      aboutText1:'',
      aboutText2:'',
      aboutText3:'',
      conteTitle:'',
      message:'1',
      windowWidth:0
    }
  },
  mounted() {
    EventBus.$on('custom-event', this.receiveMessage);
    
    this.receiveMessage(window.localStorage.getItem('message')||'1')
    this.getData()
    // this.generateQRCode('http://d.fuguizhukj.cn/update/sz100os0816.apk')
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize()
  },
  beforeDestroy() {
    EventBus.$off('custom-event', this.receiveMessage);
    window.removeEventListener('resize', this.handleResize);
    
  },
  methods: {
    generateQRCode(url,id) {
      QRCode.toCanvas(document.getElementById(id), url, function (error) {
        if (error) console.error(error);
        console.log('QR Code generated!');
      });
    },
    getData(){
      const that = this;
      getList({
          "fileName": "2000publish.json"
      }).then(res => {
        if(this.windowWidth>=480){

        
            // 合并数组
            const mergedArray = res.data.map(item1 => {
              // 根据当前项的name属性在第二个数组中找到匹配的项
              const item2 = that.itemArray.find(item2 => item1.system === item2.system);
              // 如果找到匹配项，则合并对象
              if (item2) {
                return { ...item1, ...item2 };
              }
              // 如果没有找到匹配项，可以返回原始项或者根据需要进行处理
              return item1;
            });
            that.itemArray = mergedArray;
            that.itemArray.forEach((item,index) => {
              if(item.type==2){
                that.generateQRCode(item.downloadUrl,item.system)
              }
            })
          }else{
            const mergedArray1 = that.iphoneArray.map(item1 => {
                // 根据当前项的name属性在第二个数组中找到匹配的项
                const item2 = res.data.find(item2 => item1.system === item2.system);
                // 如果找到匹配项，则合并对象
                if (item2) {
                  return { ...item1, ...item2 };
                }
                // 如果没有找到匹配项，可以返回原始项或者根据需要进行处理
                return item1;
              });
              that.iphoneArray = mergedArray1;
              console.log(mergedArray1)
          }

       
      })
    },
    handleSureBtn(index){
      this.downLoadIndex = index
        if(index){
          this.downLoadIndex = index
          this.copyContent()
          Toast.success(this.message=='1'?'官网地址已复制，请前往电脑浏览器':'The official website address has been copied, please go to the computer browser.');
         
        }
        this.show = false;
        setTimeout(()=>{
            this.downLoadIndex = null
          },500)
        
    },
    copyContent() {
      // 创建一个临时的 textarea 元素
      const tempTextarea = document.createElement('textarea');
      // 设置 textarea 的内容
      tempTextarea.value = 'http://www.shanzhua.com/';
      // 将 textarea 放到 body 中，但不显示
      document.body.appendChild(tempTextarea);
      // 选中 textarea 中的内容
      tempTextarea.select();
      // 执行浏览器的复制命令
      document.execCommand('copy');
      // 从 body 中移除临时的 textarea
      document.body.removeChild(tempTextarea);
    },
    moreDownload(){
     this.show = true;
    },
    goPage(url) {
      this.$router.push(url);
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    downloadFile(type,url,index) {
      this.downLoadIndex = index;
      if(type==1){
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'filename.txt');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url); // 释放URL对象
      }
      setTimeout(()=>{
          this.downLoadIndex = null
        },300)
      
    },
    receiveMessage(message) {
      this.message = message;
      if(this.message=='1'){
        this.IndexCont.Title='立即下载闪抓，体验安全、快速、智能的文件共享'
        this.infoImg12 = this.infoImg3
        this.infoImg22 = this.infoImg4
        this.aboutText1 = '关于我们'
        this.aboutText2 = '隐私政策'
        this.aboutText3 = '用户协议'
        this.conteTitle = '特点'
      }else{
        this.IndexCont.Title='Download SwiftShare Now and Experience Secure, Speedy, Smart File Sharing'
        this.infoImg12 = this.infoImg1
        this.infoImg22 = this.infoImg2
        this.aboutText1 = 'about us'
        this.aboutText2 = 'privacy policy'
        this.aboutText3 = 'user agreement'
        this.conteTitle = 'Features'
      }
    }
  }
};
</script>

<style lang="less" scoped>
.containerHeader{
  width: calc(100vw - 240px);
  padding: 30px 120px 50px;
  background: #D2D7F3;
  .row{
    padding: 30px 0 0;
  }
  .inner{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title{
      font-size: 36px;
      width: calc(100% - 800px);
    }
    .btnGruopCont{
      width: 16%;
    .btnGruop{
        width: 100%;
      border-radius: 12px;
      // border: 1px solid #000;
       
      background: #000;
      border-radius: 12px;
      transition: transform 0.5s ease, box-shadow 0.5s ease; /* 1秒的缓慢过渡效果 */
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.01); /* 初始阴影 */
      transform: scale(1); /* 初始缩放比例 */
      overflow: hidden;
      &:hover{
        transform: scale(1.05); /* 鼠标悬停时放大到110% */
        box-shadow: 0 4px 16px rgba(197, 152, 255, 1); /* 鼠标悬停时增加阴影 */
      }
      img{
        display: block;
        width: 100%;
        cursor: pointer;
        margin: 0;
        padding:0;
        line-height: 1;
      }
    }
      .upDateTime{
        font-size: 12px;
        color: #4F5993;
        text-align: center;
      }
    }
   
  }
  .myInner{
    margin-top: 30px;
    .btn{
      width: 100%;
      // height: 50px;
      padding: 5px 0;
      background: #fff;
      font-size: 16px;
      font-weight: bold;
      color: #111;
      
      border-radius: 12px;
      cursor: pointer;
      margin-top: 15px;
      img{
        width: 30px;
        height: 30px;
        margin-right: 6px;
      }
      div{
        display: flex;
        justify-content: center;
        align-items: center;
        }
      .upDateTime{
        font-size: 12px;
        color: #4F5993;
        text-align: center;
        margin: 3px 0 0;
        font-weight: normal;
      }
    }
    .btnClick{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      font-size: 16px;
      
      .el-button,i{
        color: #7F7FD6;
      }
      
    }
  }

}
.containerBody{
  width: 1185px;
  padding: 0 calc((100vw - 1185px) / 2);
  background: #fff;
  .contentTitle{
    font-size: 36px;
    text-align: center;
    margin: 0;
    padding-top: 70px;
  }
  .row{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 30px;
    .skyCont{
      width: 333px;
      height: 225px;
      background: #F1F4FC;
      border-radius: 10px;
      padding: 20px;
      transition: transform 0.5s ease, box-shadow 0.5s ease; /* 1秒的缓慢过渡效果 */
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.01); /* 初始阴影 */
      transform: scale(1); /* 初始缩放比例 */
      &:hover{
        // box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
        // transform: scale(1.05);
        transform: scale(1.05); /* 鼠标悬停时放大到110% */
        box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15); /* 鼠标悬停时增加阴影 */
      }
      .imgCont{
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          width: 68px;
          height: 68px;
        }
      }
      .title,.desc{
        text-align: center;
        margin: 0;
      }
      .title{
        font-size: 28px;
        line-height: 1.5;
        margin: 15px 0 10px;
      }
      .desc{
        font-size: 20px;
        line-height: 1.5;
      }
      
    }
  }
}
.containerFooter{
  margin-top: 40px;
  width: calc(100vw - 240px);
  height: 60px;background: #272442;
  color: #fff;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 120px;
  .inner{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .about{
    width: 300px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
}

.headerContent{
  display: flex;
  padding: 16px 20px 10px;
  justify-content: space-between;
  align-items: center;
  .cancelBtn{
    color:#666666
  }
  .sureBtn{
    color: #6C65C6;
  }
}
.bodyContent{
  .loadRow{
    display: flex;
    margin:10px 20px;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    padding: 16px 12px;
    border-radius: 12px;
    .loadCont{
      width: 20px;
      height: 20px;
    }
   .inner{
    display: flex;
    align-items: center;
      img{
        width: 30px;
        height: 30px;
        margin-right: 6px;
      }
   }
  }
}

// 手机和小型设备的样式
@media (max-width: 480px) {
  .containerHeader{
    width: calc(100vw - 40px);
    padding: 30px 20px 50px;
    background: #D2D7F3;
    .inner{
      display: block;
      .title{
        width: 100%;
        font-size: 28px;
      }
      video{
        width: 100%;
        height: auto;
      }
    }
  }
  .containerBody{
    width: calc(100vw - 40px);
    padding: 0 20px;
    background: #fff;
    // .contentTitle{

    // }
    .row{
      display: block;
      .skyCont{
        width: calc(100vw - 80px);
        height: auto;
        margin-bottom: 20px;
        .imgCont{
          img{
            width: 50px;
            height: 50px;
          }
        }
        .title{
          font-size: 28px;
        }
      }
    }
  }
  .containerFooter{
    width: calc(100vw - 40px);
    height: auto;
    padding: 0 20px;
    display: block;
    padding-bottom: 20px;
    p{
      margin: 0;
    }
    .about{
      width: 100%;
      padding:20px 0 40px;
    }
    .inner{
      display: block;
      line-height: 2;
    }
  }
}
</style>