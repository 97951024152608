
<template>
 
  <div class="pageContent">
    <iframe v-if="message=='1'" style="width: 100%;height: 100vh;" src="https://cms.fuguizhukj.cn/article/detail/szcsyszc.html" frameborder="0"></iframe>
    <iframe v-else style="width: 100%;height: 100vh;" src="https://cms.fuguizhukj.cn/article/detail/xcggyszc.html" frameborder="0"></iframe>
    <Footer/>
  </div>
  
 </template>
 
 
 <script>
 import { EventBus } from '../../store/eventBus.js';
 import Footer from '../../components/footer/index.vue';
 export default {
  components: {
    Footer
  },
   data() {

     return {
      message:'1'
     };
   },
   watch: {
     
   },
   onLoad() {
   },
   mounted() {
    EventBus.$on('custom-event', this.receiveMessage);
    this.receiveMessage(window.localStorage.getItem('message')||'1')
   },
   methods: {
    receiveMessage(message) {
      this.message = message;
    }
     
   },
 };
 </script>
 <style lang="less" scoped>

 </style>